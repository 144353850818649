import type { FC } from 'react'

import { CartError } from 'features/Cart/components/CartError/CartError'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CartRoomDayList } from 'features/Cart/components/CartRoomDayList/CartRoomDayList'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'
import type { ILeaseInfoRoom, IRoomTimeSlot } from 'features/Lease/interfaces/ILeaseInfoRoom'

interface IProps {
  roomInfo: ILeaseInfoRoom
  handleEditRoom?: (room: ILeaseInfoRoom) => void
  handleUpdateRoom: (slot: IRoomTimeSlot, date: string, room: ILeaseInfoRoom) => void
}

export const CartRoomCard: FC<IProps> = ({ roomInfo, handleEditRoom, handleUpdateRoom }) => {
  const handleRemoveSlot = (slot: IRoomTimeSlot, date: string): void => {
    handleUpdateRoom(slot, date, roomInfo)
  }
  const roomIDToAlphabet = (roomId: number): string => {
    if (roomId < 1 || roomId > 26) {
      throw new Error(CART_CONSTANTS.INVALID_ROOM_ID)
    }
    return String.fromCharCode(64 + roomId)
  }

  return (
    <CartItem
      price={roomInfo.price}
      img={roomInfo.room?.images?.at(0)}
      name={`${roomInfo.room?.name} ${roomIDToAlphabet(roomInfo.id)}`}
      handleEdit={() => handleEditRoom(roomInfo)}>
      <CartError error={roomInfo?.error_message} />
      <CartRoomDayList handleRemoveSlot={handleRemoveSlot} reservedDays={roomInfo.selectedDays} />
    </CartItem>
  )
}
