import { useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'
import { PATHS } from 'routes'

import { useScreenWidth } from 'app'
import { useAuth } from 'app/providers'

import { ReactComponent as BackIcon } from 'common/assets/icons/back.svg'
import logo from 'common/assets/images/logo-text.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_MODIFIER, BUTTON_PROPORTION } from 'common/constants/buttonConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import type { IRequestResponse } from 'common/interfaces/IRequestResponse'
import { useLazyFetchSitesQuery } from 'common/store/api/rootApi'

import { AUTH_CONSTANTS } from 'features/Auth/constants/authConstants'
import { CartIcon } from 'features/Cart'
import { CartWidget } from 'features/Home/components/HomeLease/CartWidget/CartWidget'
import type { ISettingsProfile } from 'features/Settings/index'
import { useFetchProfileQuery } from 'features/Settings/index'
import { Widget } from 'features/Widget'

import styles from './header.module.scss'
import HeaderLinks from './HeaderLinks'

const Header = () => {
  const {
    user,
    isSuperAdminAsDoctor,
    updateName,
    logoutAsDoctor,
    hasCompletedDocuments,
    updateDocuments,
    isSidebarVisible,
    isStaff,
    toggleIsSidebarVisible,
  } = useAuth()

  const navigate = useNavigate()
  const params = useParams()
  const headerRef = useRef<HTMLElement>(null)

  const [fetchSites] = useLazyFetchSitesQuery()

  let profileData: ISettingsProfile | undefined

  if (user) {
    const response: IRequestResponse<ISettingsProfile> = useFetchProfileQuery()
    profileData = response.data
  }

  useEffect(() => {
    if (profileData) {
      updateDocuments(profileData.documents)
    }
    if (profileData && profileData?.profile.name !== user?.name) {
      const { first_name, last_name } = profileData.profile
      updateName(first_name, last_name)
    }
  }, [profileData])

  useEffect(() => {
    fetchSites()
    const handleScroll = () => {
      if (window.pageYOffset > 0 && headerRef && headerRef.current)
        headerRef.current.classList.add(styles.parentSticky)
      else if (headerRef?.current?.classList?.contains(styles.parentSticky))
        headerRef.current.classList.remove(styles.parentSticky)
    }
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleGoBack = (): void => {
    navigate(-1)
  }

  const { isMobile } = useScreenWidth()
  return (
    <header ref={headerRef} className={styles.parent}>
      <Show when={user && isMobile}>
        <Button
          className={classNames(styles.parentBurgerMenu, {
            [styles.parentBurgerMenuActive]: isSidebarVisible,
          })}
          onClick={toggleIsSidebarVisible}
          modifier={BUTTON_MODIFIER.DEFAULT}
          proportion={BUTTON_PROPORTION.MEDIUM}>
          <div className={styles.parentHamburgerLines}>
            <span className={classNames(styles.parentHamburgerLine, styles.parentHamburgerLine1)} />
            <span className={classNames(styles.parentHamburgerLine, styles.parentHamburgerLine2)} />
            <span className={classNames(styles.parentHamburgerLine, styles.parentHamburgerLine3)} />
          </div>
        </Button>
      </Show>
      <Show when={!isMobile}>
        <img
          className={styles.parentSidebarLogo}
          src={logo}
          alt={ALT_CONSTANTS.LOGO_ICON}
          onClick={() => (window.location.href = AUTH_CONSTANTS.MAIN_WEBSITE_URL)}
        />
      </Show>
      <Show
        when={params?.tabKey && params?.ticketId}
        fallback={
          <>
            {user && (
              <span className={styles.parentWelcome}>
                {isSuperAdminAsDoctor ? APP_CONSTANTS.LOGGED_IN_AS : APP_CONSTANTS.WELCOME}
                <span className={styles.parentName} data-admin={isSuperAdminAsDoctor}>{`${
                  user?.first_name || APP_CONSTANTS.DOCTOR
                } ${user?.last_name ?? ''}`}</span>
                {isSuperAdminAsDoctor && (
                  <Button
                    proportion={BUTTON_PROPORTION.SMALL}
                    modifier={BUTTON_MODIFIER.TERTIARY}
                    className={styles.parentBackDoctor}
                    onClick={logoutAsDoctor}>
                    {APP_CONSTANTS.BACK}
                  </Button>
                )}
              </span>
            )}
          </>
        }>
        <Button onClick={handleGoBack} className='reset-button center'>
          <BackIcon />
        </Button>
      </Show>

      <div
        className={styles.parentContent}
        onClick={() => isMobile && isSidebarVisible && toggleIsSidebarVisible()}>
        <HeaderLinks />
        <Show when={hasCompletedDocuments || isStaff}>
          <CartIcon />
        </Show>

        <Show when={!user} fallback={<Widget userProfile={profileData} />}>
          <Button onClick={() => window.location.reload()}>
            <Link to={PATHS.LOGIN}>{AUTH_CONSTANTS.LOG_IN}</Link>
          </Button>
        </Show>

        {/*Need for v2.0*/}
        {/*<Button className='reset-button'>*/}
        {/*  <img src={notificationIcon} alt={ALT_CONSTANTS.NOTIFICATION_ICON} />*/}
        {/*</Button>*/}
      </div>

      <Show when={!!user}>
        <CartWidget />
      </Show>
    </header>
  )
}

export default Header
