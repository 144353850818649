import type { FC, MouseEvent } from 'react'
import { useCallback } from 'react'
import { useLocalStorage } from 'react-use'

import cartIcon from 'common/assets/icons/cart-filled.svg'
import collapse from 'common/assets/icons/collapse.svg'
import expand from 'common/assets/icons/expand.svg'
import cartBird from 'common/assets/images/cart-bird.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import { useAppDispatch } from 'common/hooks/redux'
import { UtilService } from 'common/services/utilService'

import { CartIcon, TOGGLE_IS_OPEN_CART_DETAILS, useCartManager } from 'features/Cart'

import styles from './cartWidget.module.scss'

export const CartWidget: FC = () => {
  const [cartWidgetCollapsed, setCartWidgetCollapsed] = useLocalStorage<boolean>(
    LOCALE_STORAGE_KEYS.CART_WIDGET_COLLAPSED,
    false,
  )
  const { cartAmount } = useCartManager()

  const dispatch = useAppDispatch()

  const toggleOpenCart = useCallback(() => {
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
  }, [])

  const toggleCollapse = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setCartWidgetCollapsed(!cartWidgetCollapsed)
    },
    [cartWidgetCollapsed],
  )

  return (
    <>
      {!!cartAmount && (
        <div
          className={
            !cartWidgetCollapsed ? styles.widgetContainer : styles.widgetContainerCollapsed
          }>
          {cartWidgetCollapsed ? (
            <div className={styles.widgetCartContainerCollapsed} onClick={toggleOpenCart}>
              <CartIcon isEmbedded />
              <div className={styles.widgetTogglerExpanded} onClick={toggleCollapse}>
                <img src={expand} alt={ALT_CONSTANTS.ACTION_ICON} />
              </div>
            </div>
          ) : (
            <>
              <img className={styles.widgetImage} src={cartBird} alt={ALT_CONSTANTS.CART_ICON} />
              <div className={styles.widgetTogglerCollapsed} onClick={toggleCollapse}>
                <img src={collapse} alt={ALT_CONSTANTS.ACTION_ICON} />
              </div>
              <div className={styles.widgetCartContainer} onClick={toggleOpenCart}>
                <p className={styles.widgetItemsInCart}>
                  <CartIcon isEmbedded />
                  <span>items in Cart</span>
                </p>

                <div className={styles.widgetAmount}>
                  <p>{UtilService.numberToDollar(cartAmount)}</p>
                  <p>
                    <img
                      className={styles.widgetCartIcon}
                      src={cartIcon}
                      alt={ALT_CONSTANTS.CART_ICON}
                    />
                    {'Cart ->'}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
