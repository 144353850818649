import { NavLink } from 'react-router-dom'

import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { UtilService } from 'common/services/utilService'
import { ELeasTypeCode, ELeases } from 'common/types/lease.types'
import type { TAG_STATUS } from 'common/types/tagStatusType'

import type { ISettingHistory } from 'features/Settings/interfaces/ISettingHistory'
import type { ISettingsMailbox } from 'features/Settings/interfaces/ISettings'

export enum SETTINGS_ADD_CARD_CONSTANTS {
  NUMBER = 'Card number',
  EXPIRY_DATE = 'Expiry date (MM/YY)',
  CVC = 'Security Code (CVC)',
}

export enum SETTINGS_CONSTANTS {
  PAYMENT = 'Payment',
  ADD_CARD = 'Add Card',
  EDIT_CARD = 'Edit Card',
  NO_CARD_ADDED = 'No card added',
  PAYMENT_METHOD = 'Default payment method',
  BILLING_HISTORY = 'Billing History',
  PAYMENTS_HISTORY = 'Payments History',
  MAILBOX_PROFILE = 'Receive mail addressed to',
  ADD_ANOTHER_CARD = 'Add another card',
  INVOICE = 'invoice',
  ADD_A_NEW_CARD = 'Add a new card',
  NEED_ADD_CARD = 'You’ll not be able to make a booking without a default card added into your account',
}

export enum SettingsMailboxLabels {
  fullName = 'Full Name',
  company = 'Company',
  address = 'Address',
  city = 'City',
  state = 'State',
  signInTheBpx = 'Sign in the box',
  postalCode = 'Postal Code',
  messagingID = 'Text Messaging ID',
  toBeNotified = 'I want to be notified when I receive mail',
  mailNameAsCustomer = 'Mail name same as customer name',
  toBeNotifiedDescription = 'By providing a mobile telephone number or email address above, you consent that we can send to you text or email messages relating to your use of Mailbox.Your mobile carrier may impose charges to you as a result of such text or email messages. Message and rate may apply',
  emailAddress = 'Email address',
  businessTelephone = 'Business telephone',
  mobileTelephone = 'Mobile telephone',
  homeTelephone = 'Home telephone',
  fax = 'Fax',
  firmName = 'Firm Name',
  homePhone = 'Home Phone',
  businessPhone = 'Business phone',
  businessType = 'Business type',
  forwardingPhone = 'Forwarding phone',
  forwardingAddress = 'Forwarding address',
  initials = 'Initials',
  phoneNumber = 'Phone number',
}

export enum SettingsMailboxKeys {
  fullName = 'fullName',
  company = 'company',
  address = 'address',
  city = 'city',
  state = 'state',
  postalCode = 'postalCode',
  messagingID = 'messagingId',
  mailAsCustomer = 'mailAsCustomer',
  toBeNotified = 'toBeNotified',
  emailAddress = 'emailAddress',
  businessTelephone = 'businessTelephone',
  mobileTelephone = 'mobileTelephone',
  homeTelephone = 'homeTelephone',
  fax = 'fax',
}

export enum SETTINGS_BILLING_LABELS {
  STATE = 'State',
  ADDRESS = 'Address',
  COUNTRY = 'Country',
  FULL_NAME = 'Cardholder name',
  APARTMENT = 'Address (line 2)',
  CITY = 'City',
  ZIP_CODE = 'ZIP Code',
  BILLING_ADDRESS = 'Billing address',
}
export const INITIAL_MAILBOX_DATA: ISettingsMailbox = {
  mailAsCustomer: false,
  fullName: '',
  company: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  messagingID: '',
  toBeNotified: false,
  emailAddress: '',
  businessTelephone: '',
  mobileTelephone: '',
  homeTelephone: '',
  fax: '',
}

export const HISTORY_TABLE_COLUMNS = [
  {
    title: 'Invoice',
    dataIndex: 'invoice_number',
    key: 'invoice_number',
    type: 'text',
    editable: false,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      a.invoice_number.localeCompare(b.invoice_number),
  },
  {
    title: 'Booking ID',
    dataIndex: 'lease_id',
    key: 'lease_id',
    type: 'text',
    className: 'table-col-min-width',
    editable: false,
    render: (_: string, data: ISettingHistory) => {
      const leaseType =
        data.lease_type_code === ELeasTypeCode.booking
          ? ELeases.Booking
          : data.lease_type_code === ELeasTypeCode.mailbox
          ? ELeases.Mailbox
          : ELeases.Storage
      return (
        <NavLink
          className='navigation-link'
          to={`/home/${data.db_connection}/bookings/${data.lease_id}/details?type=${leaseType}`}>
          {data.lease_id}
        </NavLink>
      )
    },
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.lease_id.localeCompare(b.lease_id),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    type: 'text',
    className: 'table-col-min-width-md',
    editable: false,
    render: (amount: number) => UtilService.numberToDollar(amount),
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.amount - b.amount,
  },
  {
    title: 'Points',
    dataIndex: 'points',
    key: 'points',
    type: 'text',
    className: 'table-col-min-width-md',
    editable: false,
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.points - b.points,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    type: 'text',
    editable: false,
    render: (date: string) => UtilService.formatTableDate(date),
    sorter: (a: ISettingHistory, b: ISettingHistory) => Date.parse(a.date) - Date.parse(b.date),
  },
  {
    title: 'Deposit',
    dataIndex: 'deposit',
    key: 'deposit',
    type: 'text',
    className: 'table-col-min-width-md',
    editable: false,
    render: (val: string | number) =>
      typeof val === 'number' ? UtilService.numberToDollar(val) : val,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      UtilService.sortNumberString(a.deposit, b.deposit),
  },
  {
    title: 'Extra Charge',
    dataIndex: 'extra_charge',
    key: 'extra_charge',
    type: 'text',
    width: 120,
    editable: false,
    render: (val: string | number) =>
      typeof val === 'number' ? UtilService.numberToDollar(val) : val,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      UtilService.sortNumberString(a.extra_charge, b.extra_charge),
  },
  {
    title: 'Deposit Refund',
    dataIndex: 'deposit_refund',
    key: 'deposit_refund',
    type: 'text',
    editable: false,
    render: (val: string | number) =>
      typeof val === 'number' ? UtilService.numberToDollar(val) : val,
    sorter: (a: ISettingHistory, b: ISettingHistory) =>
      UtilService.sortNumberString(a.deposit_refund, b.deposit_refund),
  },
  {
    title: 'Status',
    dataIndex: 'status_name',
    key: 'status_name',
    type: 'text',
    editable: false,
    render: (status_name: string) => <TagStatus status={status_name as TAG_STATUS} />,
    sorter: (a: ISettingHistory, b: ISettingHistory) => a.status_name.localeCompare(b.status_name),
  },
]
