import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Tabs } from 'antd'
import { PATHS } from 'routes'

import { useScreenWidth } from 'app'
import { useAuth } from 'app/providers'

import type { INavigationTab } from 'common/interfaces/ISidebarTab'
import type { ITabPanel } from 'common/interfaces/ITabPanel'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import styles from './tabPanel.module.scss'

import 'antd/lib/tabs/style/css'

const { getIsValidAndCurrentDay } = DateService

export const TabPanel: FC<ITabPanel> = ({
  onChange,
  polkaDotCurrentDay,
  independently = false,
  propActiveKey,
  navigationList,
  defaultRedirectPath = PATHS.HOME_ONGOING,
  timeZone,
  callback,
  forceActiveTab = false,
  ...rest
}) => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState<string>('')
  const { isMobile } = useScreenWidth()
  const { isSidebarVisible, toggleIsSidebarVisible } = useAuth()
  const handleChangeActiveTab = useCallback(
    (tabKey: string): void => {
      const newLocation: string = location.pathname
        .split('/')
        .map((path: string): string => (path === params?.tabKey ? tabKey : path))
        .join('/')
      if (callback) callback(tabKey)

      navigate(newLocation)
      if (propActiveKey) onChange(tabKey)
      else setActiveKey(tabKey)
    },
    [location],
  )
  useEffect(() => {
    if (!independently && !forceActiveTab) {
      const isValidTab: boolean = navigationList.some(
        (tab: INavigationTab): boolean => tab.path === params?.tabKey,
      )
      if (isValidTab) {
        if (onChange) {
          onChange(params?.tabKey)
        } else setActiveKey(params?.tabKey)
      } else {
        navigate(defaultRedirectPath)
      }
    }
  }, [params?.tabKey])

  const tabsProps = useMemo(
    () =>
      !independently
        ? {
            activeKey: propActiveKey || activeKey,
            onChange: onChange || handleChangeActiveTab,
          }
        : {
            ...(forceActiveTab && { activeKey: propActiveKey }),
            onChange,
          },
    [navigationList, activeKey],
  )
  const tabItems = navigationList?.map((tab: INavigationTab) => ({
    key: String(tab.path),
    tab:
      polkaDotCurrentDay && getIsValidAndCurrentDay(tab.title, timeZone) ? (
        <span
          className={styles.parent}
          data-cy={`tab-${UtilService.replaceSpaceToUnderscore(tab.title)}`}>
          {tab.title}
          <span className={styles.parentDot} />
        </span>
      ) : (
        <span data-cy={`tab-${UtilService.replaceSpaceToUnderscore(tab.title)}`}>{tab.title}</span>
      ),
    label:
      polkaDotCurrentDay && getIsValidAndCurrentDay(tab.title, timeZone) ? (
        <span
          onClick={() => {
            if (isSidebarVisible && isMobile) {
              toggleIsSidebarVisible?.()
            }
          }}>
          {tab.title}
          <span className={styles.parentDot} />
        </span>
      ) : (
        <span data-cy={`tab-${UtilService.replaceSpaceToUnderscore(tab.title)}`}>{tab.title}</span>
      ),
    children: tab.component,
    disabled: tab?.disabled,
  }))

  return <Tabs {...{ ...tabsProps, ...rest }} items={tabItems} />
}
